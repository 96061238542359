// next
import NextLink from 'next/link';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// routes
import { PATH_PAGE } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useLocales } from 'src/locales';
import { RootState, useSelector } from 'src/redux/store';
import { boringAvatarGenerator } from 'src/utils/boringAvatars';
import { capitalCase } from 'change-case';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { canvas } = useSelector((state: RootState) => state.canvas); 

  const { translate, currentLang } = useLocales();

  return (
    <NextLink href={PATH_PAGE.canvas} passHref>
      <Link underline="none" color="inherit">
        <StyledRoot>
          <CustomAvatar 
            src={canvas?.photoUrl || boringAvatarGenerator(canvas.name, 'bauhaus')} 
            alt={canvas?.name} 
            name={canvas?.name} 
          />

          <Box sx={{ ml: 2, minWidth: 0 }}>
            <Typography variant="subtitle2" noWrap>
              {canvas.name || (currentLang.value === 'en' ? capitalCase(translate('canvas.no_canvas')) : translate('canvas.no_canvas'))}
            </Typography>
          </Box>
        </StyledRoot>
      </Link>
    </NextLink>
  );
}
