// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/label';
import Iconify from 'src/components/iconify/Iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chart: icon('ic_chart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  overview: icon('ic_dashboard'),
  assets: icon('ic_booking'),
  entity: icon('ic_entity'),
  wallet: icon('ic_wallet'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { 
        title: 'overview',
        path: PATH_DASHBOARD.general.overview,
        icon: ICONS.overview
      },
      {
        title: 'entity.entities',
        path: PATH_DASHBOARD.entities.root,
        icon: ICONS.entity
      },
      {
        title: 'asset.assets',
        path: PATH_DASHBOARD.assets.root,
        icon: ICONS.assets
      },
      {
        title: 'wallet.wallets',
        path: PATH_DASHBOARD.wallets.root,
        icon: ICONS.wallet
      },
      {
        title: 'expense.expenses',
        path: PATH_DASHBOARD.expenses.root,
        icon: ICONS.wallet
      },
      {
        title: 'income_resource.income_resources',
        path: PATH_DASHBOARD.incomeResources.root,
        icon: ICONS.wallet
      },
      {
        title: 'income_transaction.income_transactions',
        path: PATH_DASHBOARD.incomeTransactions.root,
        icon: ICONS.wallet
      },
      {
        title: 'spent_transaction.spent_transactions',
        path: PATH_DASHBOARD.spentTransactions.root,
        icon: ICONS.wallet
      },
      {
        title: 'chart.chart',
        path: PATH_DASHBOARD.chart.root,
        icon: ICONS.chart
      },
    ],
  },
];

export default navConfig;
